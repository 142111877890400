<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable >
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        v-model="queryParams.time"
                        :type="queryParams.type === 'date' ? 'date' : 'month'"
                        :format="format"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="queryParams.type === 'month'">
                <el-select v-model="queryParams.driverId" placeholder="选择司机" clearable filterable>
                    <el-option v-for="(item, index) in driverData" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch" :loading="queryLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="1.5">
            <el-col :span="24">
                <el-button :size="$store.getters.size" type="primary" icon="el-icon-download"
                           plain @click="exportExcel" v-if="$app.haveRole('delivery:freight:export')">运费导出</el-button>
                <el-button :size="$store.getters.size" type="primary"
                           @click="updateFreightDialogSync = true" :disabled="rowData.length <= 0"
                           v-if="$app.haveRole('delivery:freight:update:all')">批量修改运费</el-button>
                <el-radio-group size="mini" v-model="queryParams.type" style="float: right;margin-right:80px;">
                    <el-radio-button label="month">月</el-radio-button>
                    <el-radio-button label="date">日</el-radio-button>
                    <el-radio-button label="monthSum">月统计</el-radio-button>
                </el-radio-group>
                <span style="float:right;margin-right: 40px;line-height: 1.8;color: fuchsia;">红色字体代表 “有特殊运费” ，有背景色代表 “补单” 数据</span>
            </el-col>
        </el-row>

        <div id="tableContent" style="width: 100%;">
            <freight-month ref="freight" v-if="queryParams.type === 'month'"/>
            <freight-date ref="freight" v-else-if="queryParams.type === 'date'" />
            <freight-month-sum ref="freight" v-else-if="queryParams.type === 'monthSum'" />
        </div>

        <el-dialog title="批量设置运费" :visible.sync="updateFreightDialogSync" :close-on-click-modal="false" width="400px" top="30vh">
            <el-form :model="params" label-width="100px" size="mini">
                <el-form-item label="基础运费">
                    <el-input-number v-model="params.basicFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="平台服务费">
                    <el-input-number v-model="params.serviceFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="特殊运费">
                    <el-input-number v-model="params.specialFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="updateFreightDialogSync = false">取消</el-button>
                <el-button type="primary" @click="editFreight" :loading="editFreightLoading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // 司机运费
    import FreightMonth from './freight_month'
    import FreightDate from './freight_date'
    import FreightMonthSum from './freight_month_sum'
    import {getAllDriver} from '@/api/system/driver'
    import {exportExcel, updateFreightAll} from '@/api/delivery/freight'

    export default {
        name: "Freight",
        components:{FreightMonth, FreightDate, FreightMonthSum},
        data(){
            return{
                queryParams: {
                    warId: undefined,
                    type: 'month',
                    time: undefined,
                    driverId: undefined,
                    driverName: undefined,
                    warName: undefined
                },
                updateFreightDialogSync: false,
                format: 'yyyy-MM',
                warehouseOption: this.$store.getters.warehouse,
                driverData: [],
                queryLoading: false,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的
                    }
                },
                params: {
                    serviceFreight: 5,     //平台服务费
                    specialFreight: 0,     //特殊服务费
                    basicFreight: 20,       //基础运费
                    type: undefined,
                    warId: undefined,
                    time: undefined,
                    driverId: undefined,
                },
                rowData: [],
                editFreightLoading: false
            }
        },
        methods: {
            querySearch(){
                if(this.queryLoading){return;}
                this.queryLoading = true;
                this.$refs['freight'].getTableData(this.queryParams);
            },
            exportExcel(){
                exportExcel(this.queryParams).then(({code}) => {})
            },
            editFreight(){
                let list = []
                this.rowData.forEach((e) => {list = list.concat(e.company)})
                this.params.company = list
                this.params.type= this.queryParams.type
                this.params.warId= this.queryParams.warId
                this.params.time= this.queryParams.time
                this.params.driverId= this.queryParams.driverId
                this.editFreightLoading = true
                updateFreightAll(this.params).then(({code}) => {
                    this.editFreightLoading = false
                    if(code === 0){
                        this.$message.success("操作成功")
                        this.querySearch()
                        this.updateFreightDialogSync = false
                    }
                })
            }
        },
        watch: {
            'queryParams.type': function (val) {
                this.rowData = []
                if(val === 'date'){
                    this.format = 'yyyy-MM-dd'
                }else{
                    this.format = 'yyyy-MM'
                }
            },
            'queryParams.warId': function (val) {
                this.driverData = [];
                this.queryParams.driverId = undefined
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.driverData = data;
                        }
                    })
                    const data = this.warehouseOption.filter(item => item.value == val)
                    this.queryParams.warName = data[0].label
                }
            },
            'queryParams.driverId': function (val) {
                if(val){
                    const data = this.driverData.filter(item => item.guid == val)
                    this.queryParams.driverName = data[0].driverName
                }
            }
        }
    }
</script>

<style scoped>

</style>
