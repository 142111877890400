<template>
    <div style="margin-top: 20px" class="freight_table_con">
        <el-table :data="tableData" :cell-style="cellStyle" height="720px" ref="table" highlight-current-row @rowClick="rowClick"
                  show-summary :summary-method="summaryMethod"  row-key="driverName"  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column label="司机" prop="driverName" width="120" align="center"></el-table-column>
            <el-table-column label="送货企业" align="center">
                <el-table-column v-for="item in 16" :label="item.toString()" :key="item" align="center">
                    <template slot-scope="scope">
                        <div :class="className(scope.row.company[item -1])">
                            <p>{{ scope.row.company[item-1] ? scope.row.company[item-1].companyName : ''}}</p>
                            <p>{{ scope.row.company[item-1] ? scope.row.company[item-1].freight : ''}}</p>
                            <p v-if="scope.row.company[item-1]">
                                <el-button type="text" size="mini"
                                           @click="getFreight(scope.row.company[item - 1].id,item, scope)">修改
                                </el-button>
                            </p>
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="合计" align="center" width="120">
                <template slot-scope="scope">
                    {{moneyTotal(scope.row)}}
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="修改运费" :visible.sync="editLoading" :close-on-click-modal="false" width="400px" top="30vh">
            <el-form :model="freightParams" label-width="100px" size="mini">
                <el-form-item label="司机姓名">
                    <el-input v-model="freightParams.driverName" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="企业简称">
                    <el-input v-model="freightParams.companyAbbr" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="总运费">
                    <el-input v-model="freightParams.freight" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="基础运费">
                    <el-input-number v-model="freightParams.basicFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="平台服务费">
                    <el-input-number v-model="freightParams.serviceFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="特殊运费">
                    <el-input-number v-model="freightParams.specialFreight" :min="0" style="width: 100%;"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="editLoading = false">取消</el-button>
                <el-button type="primary" @click="putFreight(freightParams)" >确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // 日数据
    import {getDateFreight,getFreight,putFreight} from '@/api/delivery/freight'

    export default {
        name: "FreightDate",
        data(){
            return {
                tableData: [],
                multipleSelection: [],
                editLoading: false,
                queryParams: {
                    warId: undefined,
                    type: 'month',
                    time: undefined,
                    driverId: undefined,
                    driverName: undefined,
                    warName: undefined
                },
                freightParams: {
                },
                clickIndex: '', //点击修改的下标
                clickChildIndex: '', //点击修改的横向下标
            }
        },
        updated() {
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        methods: {
            /** 高亮显示 */
            cellStyle({row, columnIndex}) {
                if(row.company[columnIndex-1]) {
                    if (row.company[columnIndex-1].cs) {
                        return 'background:#FEF0F0';
                    }
                }
            },
            /** 行统计 */
            moneyTotal({company}){
                let result = 0;
                company.forEach(function(arr) {
                    result+= (arr.freight || 0);
                });
                return result;
            },
            /** 自定义合计 */
            summaryMethod({ columns, data }){
                let sums = [];
                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = '总计';
                    }else if(index === 18){
                        let count = 0;
                        data.forEach(e => {
                            count += this.moneyTotal(e);
                        })
                        sums[index] = count;
                    }
                })
                return sums;
            },
            getTableData(data){
                getDateFreight(data).then(({code, data}) => {
                    this.$parent.queryLoading = false
                    this.tableData = data
                })
            },
            rowClick(row, column, event){
                console.log(row)
                this.$parent.rowData = row
            },
            handleSelectionChange(val) {
                this.multipleSelection = val
                this.$parent.rowData = val
            },
            className(row){
                let className = 'table_column '
                if(row){
                    if(row.supplementId){
                        className += 'bg_red '
                    }
                    if(row.specialFreight > 0){
                        className += 'font_red '
                    }
                }

                return className
            },
            getFreight(id,index, scope){
                getFreight(id).then(({code, data}) => {
                    this.clickIndex = scope.$index
                    this.clickChildIndex = index
                    getFreight(id).then(({code, data}) => {
                        this.editLoading = true
                        this.freightParams=data;
                    })
                })
            },
            putFreight(param) {
                param.type = this.$parent.queryParams.type
                param.warId = this.$parent.queryParams.warId
                param.time = this.$parent.queryParams.time
                param.driverId = this.$parent.queryParams.driverId

                param.freight = param.basicFreight + param.serviceFreight + param.specialFreight
                this.tableData[this.clickIndex].company[this.clickChildIndex - 1].freight = param.freight
                putFreight(param).then(({code, data}) => {
                    this.editLoading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
