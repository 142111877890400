import { render, staticRenderFns } from "./freight_month.vue?vue&type=template&id=2795d3a1&scoped=true"
import script from "./freight_month.vue?vue&type=script&lang=js"
export * from "./freight_month.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2795d3a1",
  null
  
)

export default component.exports